import React, {useMemo} from 'react'
// import {PriceBubble} from '../../img'
// import {ReactFitty} from 'react-fitty'
import {useNavigate} from 'react-router-dom'
import {basicGalleryImages, premiumGalleryImages, standardGalleryImages} from '../../images'
// import {useFormikContext} from 'formik'
import './tiers.css'
import {scrollIntoView} from '../../utils/scroll_into_view'
import {TierInfo} from './TierElement'

const createGalleryImages = (images) => {
	const getSrcSet = (imageArray, width) => {
		return imageArray.map((img) => `${img.path} ${width}w`).join(', ')
	}

	return images.map((image) => {
		const sources = [
			{type: 'webp', srcSet: getSrcSet(image.sm, 768), media: '(max-width: 768px)'},
			{type: 'webp', srcSet: getSrcSet(image.md, 1280), media: '(max-width: 1280px)'},
			{type: 'webp', srcSet: getSrcSet(image.lg, 1920), media: '(min-width: 1281px)'},
			...image.sm.map((img) => ({type: img.type, srcSet: getSrcSet([img], 768), media: '(max-width: 768px)'})),
			...image.md.map((img) => ({type: img.type, srcSet: getSrcSet([img], 1280), media: '(max-width: 1280px)'})),
			...image.lg.map((img) => ({type: img.type, srcSet: getSrcSet([img], 1920), media: '(min-width: 1281px)'})),
		]

		return {
			srcSet: `
				${getSrcSet(image.sm, 768)},
				${getSrcSet(image.md, 1280)},
				${getSrcSet(image.lg, 1920)}
			`,
			sizes: '(max-width: 768px) 100vw, 33vw',
			placeholder: image.thumb ? image.thumb[0].path : image.sm[0].path,
			fullVersion: image.full[0].path,
			name: image.name,
			pictureElement: (
				<picture>
					{sources.map((source, index) => (
						<source key={index} srcSet={source.srcSet} type={`image/${source.type}`} media={source.media} />
					))}
					<img src={image.full[0].path} alt={image.name} />
				</picture>
			),
		}
	})
}

const getGalleryImageByIndex = (images, index) => {
	// if (index < 0 || index >= images.length) {
	// 	throw new Error('Index out of bounds')
	// }
	return createGalleryImages(images)[index]
}

// eslint-disable-next-line no-unused-vars
const TierRow = ({plan}) => {
	const Image = ({className}) => (
		<div className={`${className} items-center max-w-xs`}>
			<img
				src={plan.image}
				alt={plan.name}
				className='rounded-md h-full w-full object-cover object-center aspect-square max-h-60 max-w-60 overflow-hidden '
			/>
		</div>
	)
	return (
		<div className='tier-row my-8 flex flex-row h-full relative max-h-[60vh]'>
			{plan.isPopular && <h5 className='inline absolute -top-5 mt-auto py-1 px-2 rounded text-xs bg-doodle text-white font-lucky'>Most Popular</h5>}
			<Image className='flex md:hidden w-[80%]' />
			<div className='flex flex-col w-full py-2'>
				<div className=''>
					<div className='col-start-2 md:col-start-1 row-start-1 md flex text-center items-center justify-center'>
						{/* <ReactFitty className='tier-name'>{tier}</ReactFitty> */}
						<div className='font-lucky text-[7vi]'>{plan.name}</div>
					</div>
					<Image className='hidden md:flex ' />
				</div>
				<div className='flex flex-col h-full'>
					{/* <div className='description mb-auto py-4'>{plan.description}</div> */}
					<div className='order-2 h-full'>
						<ul className='list-none text-left p-2 text-[3vi] sm:text-[4vi]'>
							{plan.features.map((feature, i) => (
								<li key={i}>
									<span className='text-e_green mr-2'>&#10003;</span>
									{feature}
								</li>
							))}
						</ul>
					</div>
					<p className='order-1 text-[5vi] text-center content-center font-lucky pt-2 '>{plan.price}</p>
					<div className='order-last flex justify-center button-container'>
						{/* <button
					className={`py-2 px-4 rounded font-bold ${
						plan.isPopular ? 'bg-blue-500 hover:bg-blue-700 text-white' : 'bg-gray-300 hover:bg-gray-400 text-gray-800'
						}`}>
						Get now
						</button> */}
						<TierButton tier={plan.name} />
					</div>
				</div>
			</div>
		</div>
	)
}
const TierButton = ({tier}) => {
	const navigate = useNavigate()
	const gotoContact = () => {
		scrollIntoView(null, '#contact')
		navigate(`/#contact?get=${tier}&from=table`)
	}
	// scrollIntoView()
	return (
		<button className='font-lucky text-[4vi]' onClick={gotoContact}>
			Get now!
		</button>
	)
}

const TierComparisonTable = () => {
	const basicPic = useMemo(() => getGalleryImageByIndex(basicGalleryImages, 0), [])
	const standardPic = useMemo(() => getGalleryImageByIndex(standardGalleryImages, 1), [])
	const premiumPic = useMemo(() => getGalleryImageByIndex(premiumGalleryImages, 0), [])

	const tableData = {
		basic: {
			name: 'basic',
			image: basicPic,
			// features: ['Line Art', 'Black & White', 'One character only', 'Head and shoulders only', 'No background'],
			// description: '',
			features: ['B&W style art work', 'One caricature', 'High resolution digital file'],
			// description: 'This basic tier includes options such as line art and black and white coloring.',
			price: <span className=''>$49</span>,
		},
		standard: {
			name: 'standard',
			image: standardPic,
			// features: ['All options of basic tier, plus:', 'Colored artwork', 'One character only', 'basic background', 'Additional details'],
			features: ['Vibrant colored artwork', 'Advanced (customized) details', 'Full figure', 'One caricature', 'High resolution digital file'],
			// description: '',
			// description:
			// 'This mid-range tier includes all the options of the basic tier, as well as additional options such as colored artwork. This is the most popular tier among our customers.',
			price: (
				<span className=''>
					$99<span className='text-xl'>.99</span>
				</span>
			),
			isPopular: true,
		},
		premium: {
			name: 'premium',
			image: premiumPic,
			// features: ['All in Standard +', 'Complex coloring', 'Full body option', 'Detailed background', 'Video of drawing process'],
			// features: [],
			// description: 'This top tier includes all the options of the Standard tier, as well as advanced options such as a video of the drawing process.',
			description: 'Family caricature portrait, a fine art piece on Perspex Print.',
			price: (
				<span className='flex flex-col items-center'>
					Custom
					<br />
					<span className='text-lg font-regular'>(Starts at: $799)</span>
				</span>
			),
		},
	}
	return (
		<div className='my-4 p-6 h-fit md:max-w-3xl grid gap-4 grid-flow-row md:grid-flow-col md:grid-cols-3 justify-center items-center content-center'>
			{Object.values(tableData).map((tier) => (
				<TierInfo
					tier={tier}
					handleClick={() => {}} // Provide a handleChange function as needed
					key={tier.name}
				/>
			))}
		</div>
	)
}

export default TierComparisonTable
