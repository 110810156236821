import {useEffect, useRef} from 'react'

const useKeypress = (key, action) => {
	const isKeyPressed = useRef(false)

	useEffect(() => {
		const onKeydown = (e) => {
			if (e.key === key && !isKeyPressed.current) {
				isKeyPressed.current = true
				action()
			}
		}

		const onKeyup = (e) => {
			if (e.key === key) {
				isKeyPressed.current = false
			}
		}

		window.addEventListener('keydown', onKeydown)
		window.addEventListener('keyup', onKeyup)

		return () => {
			window.removeEventListener('keydown', onKeydown)
			window.removeEventListener('keyup', onKeyup)
		}
	}, [key, action])

	return isKeyPressed
}

export default useKeypress
