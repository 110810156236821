import React, {useState, useRef, useEffect} from 'react'
import {useSwipeable} from 'react-swipeable'
import {basicGalleryImages, ForGallery, premiumGalleryImages, standardGalleryImages} from '../../images'
import {FaArrowCircleLeft, FaArrowCircleRight, FaSearchMinus, FaSearchPlus, FaTimesCircle} from 'react-icons/fa'
import {BlockScroll} from '../../utils/useLockBodyScroll'
import './index.css'
import Draggable from 'react-draggable'
import useKeypress from '../../hooks/useKeypress'
import Border from '../../components/Border'

const GalleryImage = ({image, onClick}) => {
	return (
		<div className='gallery-image-container mb-4'>
			<img
				src={image.src || image.full[0].path}
				alt={image.alt || image.name}
				className='w-full max-h-[30vh] h-auto object-cover cursor-pointer transition-transform duration-300 hover:scale-105'
				onClick={() => onClick(image)}
				onContextMenu={(e) => e.preventDefault()} // Block right-click
				draggable={false} // Prevent dragging
				loading='lazy'
			/>
		</div>
	)
}

const GalleryOverlay = ({images, currentIndex, onClose, onPrev, onNext}) => {
	const [scale, setScale] = useState(1)
	const [position, setPosition] = useState({x: 0, y: 0})
	const [leftArrowVisible, setLeftArrowVisible] = useState(true)
	const [rightArrowVisible, setRightArrowVisible] = useState(true)

	useEffect(() => {
		const leftTimeout = setTimeout(() => setLeftArrowVisible(false), 3000)
		const rightTimeout = setTimeout(() => setRightArrowVisible(false), 3000)
		return () => {
			clearTimeout(leftTimeout)
			clearTimeout(rightTimeout)
		}
	}, [leftArrowVisible, rightArrowVisible])

	useKeypress('ArrowLeft', onPrev)
	useKeypress('ArrowRight', onNext)
	useKeypress('Escape', onClose)

	const handleLeftArrowInteraction = () => {
		setLeftArrowVisible(true)
	}

	const handleRightArrowInteraction = () => {
		setRightArrowVisible(true)
	}

	const handlers = useSwipeable({
		onSwipedLeft: () => {
			if (scale === 1) onNext()
		},
		onSwipedRight: () => {
			if (scale === 1) onPrev()
		},
		onSwipedDown: () => {
			if (scale === 1) onClose()
		},
		onSwipedUp: () => {
			if (scale === 1) onClose()
		},
		preventDefaultTouchmoveEvent: true,
		trackMouse: true,
	})

	const handleZoomIn = () => setScale((prev) => Math.min(prev + 0.5, 3))
	const handleZoomOut = () => setScale((prev) => Math.max(prev - 0.5, 1))
	const handlePinch = (e) => {
		if (e.scale > 1) {
			handleZoomIn()
		} else {
			handleZoomOut()
		}
	}

	const handleDoubleTap = () => {
		setScale((prev) => (prev === 1 ? 2 : 1))
	}

	const ButtonIconViewer = ({Icon, position, btnClass, iconClass, onClick}) => {
		return (
			<button className={`text-white rounded-full bg-black bg-opacity-50 z-50 ${btnClass} ${position} hover:scale-110`} onClick={onClick}>
				<Icon className={`pointer-events-none select-none ${iconClass}`} />
			</button>
		)
	}

	const handleDragStop = (e, data) => {
		if (scale === 1) {
			setPosition({x: 0, y: 0})
		} else {
			setPosition({x: data.x, y: data.y})
		}
	}

	useEffect(() => {
		const handleKeyDown = (e) => {
			if (e.key === 'ArrowLeft') {
				onPrev()
			} else if (e.key === 'ArrowRight') {
				onNext()
			}
		}

		window.addEventListener('keydown', handleKeyDown)
		return () => {
			window.removeEventListener('keydown', handleKeyDown)
		}
	}, [onPrev, onNext])

	const handleBackgroundClick = (e) => {
		if (e.target === e.currentTarget && e.type === 'click') {
			onClose()
		}
	}
	return (
		<div
			className='fixed inset-0 bg-black bg-opacity-90 md:bg-opacity-90 sm:bg-opacity-100 z-50 flex items-center justify-center select-none'
			{...handlers}
			onTouchMove={handlePinch}
			onDoubleClick={handleDoubleTap}
			onClick={handleBackgroundClick}>
			<BlockScroll />
			<ButtonIconViewer Icon={FaTimesCircle} position={'absolute top-4 right-4 text-4xl'} iconClass={'text-4xl'} onClick={onClose} />

			<div
				className='absolute left-0 h-1/3 w-1/4 z-40'
				onMouseMove={handleLeftArrowInteraction}
				onTouchStart={handleLeftArrowInteraction}
				onClick={onPrev}>
				<ButtonIconViewer
					Icon={FaArrowCircleLeft}
					position={`absolute left-4 top-1/2 transform -translate-y-1/2 text-4xl transition-opacity duration-1000 ${
						leftArrowVisible ? 'opacity-100' : 'opacity-0'
					}`}
					iconClass={'text-4xl'}
					onClick={onPrev}
				/>
			</div>

			<div className='flex flex-col'>
				<Draggable position={position} onStop={handleDragStop} disabled={scale === 1}>
					<div className='relative gallery-overlay select-none'>
						<img
							src={images[currentIndex].src || images[currentIndex].full[0].path}
							alt={images[currentIndex].alt || images[currentIndex].name}
							className='gallery-overlay max-h-[90vh] max-w-[90vw] object-contain pointer-events-none select-none '
							onContextMenu={(e) => e.preventDefault()} // Block right-click
							draggable={false} // Prevent dragging
							style={{transform: `scale(${scale})`}}
						/>
					</div>
				</Draggable>
				<div className='pointer-events-none z-50 bg-black/50'>
					<p className='text-center text-white mt-2 select-none'>{images[currentIndex].alt || 'Placeholder text'}</p>
					<p className='text-center text-white mt-2 select-none'>
						{currentIndex + 1} / {images.length}
					</p>
				</div>
			</div>

			<div
				className='absolute right-0 h-1/3 w-1/4 z-40'
				onMouseMove={handleRightArrowInteraction}
				onTouchStart={handleRightArrowInteraction}
				onClick={onNext}>
				<ButtonIconViewer
					Icon={FaArrowCircleRight}
					position={`absolute right-4 top-1/2 transform -translate-y-1/2 text-4xl transition-opacity duration-1000 ${
						rightArrowVisible ? 'opacity-100' : 'opacity-0'
					}`}
					iconClass={'text-4xl'}
					onClick={onNext}
				/>
			</div>

			<div className='absolute bottom-4 right-4 flex space-x-4 z-50'>
				<ButtonIconViewer Icon={FaSearchMinus} position={'text-2xl p-2'} iconClass={'text-2xl'} onClick={handleZoomOut} />
				<ButtonIconViewer Icon={FaSearchPlus} position={'text-2xl p-2'} iconClass={'text-2xl'} onClick={handleZoomIn} />
			</div>
		</div>
	)
}

const createGalleryImages = (images) => {
	console.log('createGalleryImages', images)
	return images
}

const GallerySection = ({sectionTitle, subTitle, images, initialLimit = 12, text, darkTheme = false, highlights = [], useFor = true}) => {
	const [limit, setLimit] = useState(initialLimit)
	const [showOverlay, setShowOverlay] = useState(false)
	const [currentIndex, setCurrentIndex] = useState(0)
	const galleryRef = useRef(null)

	const handleImageClick = (clickedImage) => {
		const index = images.findIndex((img) => img.name === clickedImage.name)
		setCurrentIndex(index)
		setShowOverlay(true)
	}

	const handleClose = () => setShowOverlay(false)
	const handlePrev = () => setCurrentIndex((prev) => (prev - 1 + images.length) % images.length)
	const handleNext = () => setCurrentIndex((prev) => (prev + 1) % images.length)

	const loadMore = () => setLimit((prev) => prev + initialLimit)

	useEffect(() => {
		const handleScroll = () => {
			if (galleryRef.current && window.innerHeight + window.scrollY >= galleryRef.current.offsetHeight - 200) {
				loadMore()
			}
		}

		window.addEventListener('scroll', handleScroll)
		return () => window.removeEventListener('scroll', handleScroll)
	}, [])

	return (
		<div className={`gallery-section max-w-[1440px] mx-auto ${!darkTheme ? 'bg-white' : `bg-black`} py-6`}>
			<div id={sectionTitle} ref={galleryRef}>
				<div className='flex flex-wrap justify-center gap-4'>
					{images?.slice(0, limit).map((image, index) => (
						<GalleryImage key={index} image={image} onClick={handleImageClick} />
					))}
				</div>
				{showOverlay && <GalleryOverlay images={images} currentIndex={currentIndex} onClose={handleClose} onPrev={handlePrev} onNext={handleNext} />}
			</div>

			<div className='info-section'>
				{/* <SideDoodle src={doodleLeft} placeholder={doodleLeftThumb} className='hidden md:block' /> */}
				<div className='flex flex-col text-center justify-center items-center '>
					<h2 className={`${darkTheme ? 'text-white' : 'text-black'} mt-8 text-4xl font-lucky`}>
						{sectionTitle}
						<span className={`opacity-70 text-2xl text-e_gray`}>/{subTitle}</span>
					</h2>
					<p className={`${darkTheme ? 'text-white' : 'text-black'} w-2/3 md:w-3/5 text-center md:text-start mt-8 flex-grow`}>{text}</p>
					{/* <SideDoodle
						src={doodleLeft}
						placeholder={doodleLeftThumb}
						className={`w-full md:hidden block ${darkTheme ? 'order-last -mt-[40px]' : 'mt-4'}`}
					/> */}
					{useFor ? <ForGallery className={`${darkTheme ? 'invert' : ''} rotate-[25deg] w-16 h-16 mt-4 md:mt-12`} /> : <></>}
					<ul className='font-lucky text-e_red text-2xl mt-2 text-left'>
						{highlights.map((t, key) => {
							return <li key={key}>· {t}</li>
						})}
					</ul>

					{/* TODO add button */}
					{/* {<CustomGlobalButton onClick={gotoContact} gallery={darkTheme ? false : true} galleryPremium={darkTheme ? true : false} className='my-8' />} */}
				</div>
				{/* <SideDoodle src={doodleRight} placeholder={doodleRightThumb} className='hidden md:block' /> */}
			</div>
			<Border />
		</div>
	)
}

const gallerySections = (images) => [
	{
		images: images.basic,
		highlights: ['BIRTHDAY CARDS', 'INVITATIONS', 'THANK YOU CARDS'],
		text: 'Get a charming, custom designed caricature, stylized with that classic "cartoony" feel!',
		useFor: true,
		title: 'BLACK & WHITE',
		subTitle: 'BASIC',
		// doodleRight: GalleryBlackWhiteRight,
		// doodleLeft: GalleryBlackWhiteLeft,
	},
	{
		images: images.standard,
		highlights: ['GRADUATIONS', 'BIRTHDAYS', 'WEDDING GIFTS', 'YOUR FAVORITE PET'],
		// text: 'Elevate your caricature to the next level with energetic watercolors that instantly brings to life and creates a lasting impression.',
		text: (
			<>
				Elevate your caricature to the next level with energetic watercolors that instantly
				<br />
				Bring to life your caricature creating an everlasting impression.
			</>
		),
		useFor: true,
		title: 'COLOR',
		subTitle: 'STANDARD',
		// doodleRight: GalleryColorRight,
		// doodleLeft: GalleryColorLeft,
	},
	{
		images: images.premium,
		highlights: [],
		darkTheme: true,
		text: (
			<>
				Make a statement with a dynamic, painterly fine art piece, that captures the heart of your family.
				<br />
				An exceptionally meaningful gift, personalized to bring a smile on your face every time you see it.
			</>
		),
		useFor: false,
		title: 'PAINTERLY',
		subTitle: 'PREMIUM',
		// doodleRight: GalleryPainterlyRight,
		// doodleLeft: GalleryPainterlyLeft,
	},
]

const Gallery2 = () => {
	const [allImages, setGalleryImages] = useState({
		basic: [],
		standard: [],
		premium: [],
	})

	useEffect(() => {
		const loadImages = async () => {
			setGalleryImages({
				basic: createGalleryImages(basicGalleryImages),
				standard: createGalleryImages(standardGalleryImages),
				premium: createGalleryImages(premiumGalleryImages),
			})
		}

		loadImages()
	}, [])

	return (
		<div id='gallery' className='relative flex flex-col justify-start text-center items-center min-h-[100vh] w-screen'>
			<div className='hidden md:flex justify-end w-full h-auto md:absolute right-0 top-0 z-10 md:-translate-y-[40%] pointer-events-none select-none touch-none'>
				{/* <img src={Gallery_ShowBoy_lg} alt='Gallery Show Boy' className='doodle max-w-[40vw] h-full md:max-w-[30vw]' /> */}
			</div>
			<h1 className='section-title'>GALLERY</h1>
			{gallerySections(allImages).map((section, key) => {
				return (
					<div key={key}>
						<GallerySection
							sectionTitle={section.title}
							subTitle={section.subTitle}
							images={section.images}
							text={section.text}
							darkTheme={section.darkTheme}
							highlights={section.highlights}
							des={section.text}
						/>
					</div>
				)
			})}
		</div>
	)
}

export default Gallery2
