/* eslint-disable no-unused-vars */
import axios from 'axios'
import {Form, Formik, useField} from 'formik'
import React, {useState} from 'react'
import * as Yup from 'yup'
import {apiBaseUrl} from '../../utils/config'
import './contactForm.css'
import CustomGlobalButton from '../../components/CustomGlobalButton'

// const prode_mode = process.env.NODE_ENV === 'production' ? true : false
// const apiBaseUrl = !prode_mode ? 'http://localhost:5000' : 'https://api.adelezstudio.com'

// const phone = '000'
// const whatsApp_endPoint = (text) => `https://api.whatsapp.com/send?phone=${phone}&text=${text}`
// const whatsApp_endPoint2 = (text) => `https://wa.me/${phone}?text=${text}`
// // const telegram_endPoint = (text) => `https://t.me/share/url?to=${phone}&text=${text}`
// // const telegram_endPoint2 = (text) => `tg://msg?text=${text}&to=${phone}`
// const telegram_endPoint3 = () => `https://t.me/light_wing`

const telegram_endPoint = (text) => `tg://user?id=610944439&text=${text}`

// //this works, but will require a bot that is always running
// //that will accept the code and connect it to the backend to forward it to Adele
// // this will take some extra time to do
// //in the meantime, we can show the user a nicely stylized message in markdown copy bubble, and ask them to copy the message and paste it into the chat that will open...
// // const telegram_endPoint4 = (code) => `https://t.me/triviabot?start=${code}`

const email_endPoint = (subject, message) => `mailto:adele@gmail.com?cc=&bcc=&subject=${subject}&body=${message}`

const MyTextInput = ({label, ...props}) => {
	// useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
	// which we can spread on <input> and also replace ErrorMessage entirely.
	const [field, meta] = useField(props)
	return (
		<>
			<label htmlFor={props.name}>
				<span>{label}</span>
				<div className='relative form_field'>
					<input
						className={`input_area peer ${meta.touched ? (meta.error ? 'invalid border-e_red' : 'valid border-e_green') : ''}`}
						{...field}
						{...props}
					/>
					<span className='focus-border'>
						<i></i>
					</span>
				</div>
				{meta.touched && meta.error ? <div className='text-e_red'>{meta.error}</div> : null}
			</label>
		</>
	)
}
const MyTextArea = ({label, ...props}) => {
	const [field, meta] = useField(props)
	// console.log('MyTextArea', field, meta)
	return (
		<>
			<label htmlFor={props.name}>
				<span>{label}</span>
				<div className='relative form_field'>
					<textarea className={`input_area ${meta.touched ? (meta.error ? 'invalid' : 'valid') : ''}`} {...field} {...props} />
					<span className='focus-border'>
						<i></i>
					</span>
				</div>
				{meta.touched && meta.error ? <div className='error'>{meta.error}</div> : null}
			</label>
		</>
	)
}
const MySelect = ({label, ...props}) => {
	const [field, meta] = useField(props)
	return (
		<>
			<label htmlFor={props.name}>
				<span>{label}</span>
				<div className='relative form_field'>
					<select className={`input_area ${meta.touched ? (meta.error ? 'invalid' : 'valid') : ''}`} {...field} {...props} />
					<span className='focus-border'>
						<i></i>
					</span>
				</div>
				{meta.touched && meta.error ? <div className='error'>{meta.error}</div> : null}
			</label>
		</>
	)
}
const MyFiles = ({label, ...props}) => {
	const [field, meta] = useField(props)
	return (
		<>
			<label htmlFor={props.name}>
				<span>{label}</span>
				<div className='relative form_field'>
					{/* required */}
					<input
						type='file'
						name='Files'
						multiple
						className={`input_area ${meta.touched ? (meta.error ? 'invalid' : 'valid') : ''}`}
						{...field}
						{...props}
					/>
					<span className='focus-border'>
						<i></i>
					</span>
				</div>
				{meta.touched && meta.error ? <div className='error'>{meta.error}</div> : null}
			</label>
		</>
	)
}
const MyHiddenField = ({...props}) => {
	const [field, meta] = useField(props)
	return (
		<>
			<input className='hidden' {...field} {...props} />
		</>
	)
}
const formOptions = {
	// Caricature: {
	// 	value: 'Order a Custom Caricature',
	// 	placeholder: 'Tell me more about your dream caricature...',
	// },
	Feedback: {
		value: 'Provide Feedback',
		placeholder: 'Share your thoughts and opinions with me...',
	},
	Review: {
		value: 'Leave a Review',
		placeholder: 'Tell me about your experience...',
	},
	Developer: {
		value: 'Contact the Site Developer',
		placeholder: 'Ask Meir the site dev, anything...',
	},
	Other: {
		value: 'Other',
		placeholder: 'Once upon a time there was a princess who lived at the top of a glass hill. BTW, I would like to use specific colors.',
	},
}
const formKeys = Object.keys(formOptions)

export const FormContainer = ({onChange, setMessageSent, messageSent, handleBlur, handleFocus}) => {
	const [sending, setSending] = useState(false)
	const [submitInProgress, setSubmitInProgress] = useState(false)
	const [message, setMessage] = useState('')
	const [error, setError] = useState('')

	const initialValues = {
		name: '',
		email: '',
		subject: '',
		message: '',
	}

	const handleSubmit = (values, {setSubmitting, setErrors, status, resetForm}, ...props) => {
		const changeSendingStatus = (status) => {
			setSubmitting(status)
			setSending(status)
			setSubmitInProgress(status)
		}
		// e.preventDefault()
		changeSendingStatus(true)

		axios
			.post(`${apiBaseUrl}/contact`, values)
			.then((res) => {
				resetForm()
				setMessage(res?.data?.message || 'Message sent ... :)')
				changeSendingStatus(false)
				setMessageSent(true)
			})
			.catch((e) => {
				console.log('error while submitting e:', e)
				setError(e?.response?.data?.error?.message || 'Some error occured')
				changeSendingStatus(false)
			})
	}

	if (messageSent) {
		return (
			<div className='flex flex-col justify-around max-w-[400px] lg:max-w-lg w-full'>
				<h2 className='text-xl'>Thank you!</h2>
				<div>
					We'll be in touch soon.
					<br />
					I can't wait to bring your caricature to life.
					<br />
					<br />
					{message.toString()}
				</div>
			</div>
		)
	}

	const subjectOptions = formKeys.map((key) => (
		<option key={key} value={key}>
			{formOptions[key].value}
		</option>
	))

	return (
		<Formik initialValues={initialValues} validate={validate} onSubmit={handleSubmit}>
			{({isSubmitting, errors, values, onSubmit}) => (
				<Form className={'max-w-[400px] lg:max-w-[500px] w-full'} id='contact-form' onChange={onChange} onBlur={handleBlur} onFocus={handleFocus}>
					<MyHiddenField type='text' name='sbox' value='' />
					<MySelect label='Subject:' name='subject'>
						<option value='none'>Select an option</option> {subjectOptions}
					</MySelect>
					{formKeys.includes(values.subject) ? (
						<>
							<MyTextInput label='Name:' name='name' type='text' placeholder='Adele' />

							<MyTextInput label='Email:' name='email' type='email' placeholder='adele@example.com' />

							<MyTextArea label='Message:' name='message' rows='6' placeholder={formOptions[values.subject]?.placeholder || 'lmk wyt'} />
							{/* <button
								type='submit'
								onClick={onSubmit}
								style={{'--btn-color': `${Object.keys(errors).length === 0 ? '#22c55e' : '#e11d48'}`}}
								disabled={submitInProgress}
								className={`self-start sm:self-center bg-black text-black`}>
								{sending ? 'Sending...' : 'Send letter =>'}
							</button> */}
							<CustomGlobalButton
								type='submit'
								onClick={onSubmit}
								disabled={submitInProgress}
								contact={true}
								text={sending ? 'Sending...' : 'Send letter =>'}
								className=''
							/>
							{error && (
								<div className='text-e_red' role='alert'>
									{error.toString()}
								</div>
							)}
						</>
					) : null}
				</Form>
			)}
		</Formik>
	)
}

// function validate(values) {
const validate = (values) => {
	const validationSchema = getValidationSchema(values)
	try {
		validationSchema.validateSync(values, {abortEarly: false})
		return {}
	} catch (error) {
		return getErrorsFromValidationError(error)
	}
}
function getErrorsFromValidationError(validationError) {
	const FIRST_ERROR = 0
	return validationError.inner.reduce((errors, error) => {
		// console.log('getErrorsFromValidationError', errors)
		return {
			...errors,
			[error.path]: error.errors[FIRST_ERROR],
		}
	}, {})
}
function getValidationSchema(values) {
	// console.log('getValidationSchema', values)
	// console.log('values.subject === subjectOptions[0]', values.subject === subjectOptions[0])

	return Yup.object().shape({
		// showEmail: Yup.boolean(),
		// email: Yup.string()
		// 	.email('Email is not valid!')
		// 	.when('showEmail', {
		// 		is: true,
		// 		then: Yup.string().required('Email is required!'),
		// 	}),
		email: Yup.string().email().required('Email is required!'),
		name: Yup.string().min(2, 'Name has to be longer than 2 characters!').required('Name is required!'),
		subject: Yup.string()
			// .oneOf([subjectOptions], 'Please select a subject, if you want to change later, just let me know.')
			.required('Please select a subject, can be changed later!'),
		// tiers: Yup.string().oneOf([addonOptions], 'Please select an option'), //.required('Password confirmation is required!'),
		// tier:
		// 	values.subject === formKeys[0] //TODO if ever changing the words, or get it directly formOptions keys
		// 		? Yup.string().oneOf([tierOptions], 'Please select one of the options').required('Please select an option')
		// 		: false, //.required('Password confirmation is required!'),
		message: Yup.string()
			.min(5, 'Please give me some details for what you want...')
			.max(
				400,
				'Thank you for writing so much, the form is limited to 400 letters, please review your message and shorten it so it fits in this limit, so that I can read it in a timely manner'
			)
			.required('Message is required!'),
		// consent: Yup.bool()
		// 	.test('consent', 'You have to agree with our Terms and Conditions!', (value) => value === true)
		// 	.required('You have to agree with our Terms and Conditions!'),
	})
}
