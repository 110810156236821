import {useEffect, useState} from 'react'

export const SVGImageMapper = ({onClick, isMobile = true}) => {
	const baseUrl = '/'
	const BG_D = `${baseUrl}assets/img/HeroBG_1_D.png`
	const BG_M = `${baseUrl}assets/img/HeroBG_1_M.png`

	const [imgUrl, setImgUrl] = useState(isMobile ? BG_M : BG_D)
	useEffect(() => {
		setImgUrl(isMobile ? BG_M : BG_D)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isMobile])

	return (
		<svg xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink' viewBox={isMobile ? '0 0 350.4 698.6' : '0 0 595.5 439.4'}>
			<image
				href={imgUrl}
				xlinkHref={imgUrl}
				width={isMobile ? '350.4' : '595.5'}
				height={isMobile ? '698.6' : '439.4'}
				image-rendering='optimizeQuality'
				preserveAspectRatio='all'
			/>
			<path
				onClick={onClick}
				className='cursor-pointer hover:stroke-e_blue'
				fill='transparent'
				fill-opacity='.6'
				stroke-linecap='round'
				stroke-linejoin='round'
				stroke-width='1.5'
				d={
					isMobile
						? 'M94 468c10.7-.3 69.6-.4 87-.1a24.1 24.1 0 0 1 24 24.7c-.1 9.5-8.7 23.5-20.5 23.8-11.9.3-75.8 0-89 0A24.2 24.2 0 0 1 72 491.9 24.2 24.2 0 0 1 94 468z'
						: 'M192.8 288.5c7.5-.3 49-.3 61.4-.2a17 17 0 0 1 17 17.5c-.2 6.7-6.2 16.6-14.6 16.8-8.4.2-53.5 0-62.8 0a17 17 0 0 1-16.6-17.3 17 17 0 0 1 15.6-16.8z'
				}
			/>
		</svg>
	)
}
