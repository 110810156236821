import requestFactory from './RequestFactory'

const DataService = {
	getAllImages: requestFactory.post('/allImages'),
	addImage: requestFactory.post('/addImage'),
	uploadImage: requestFactory.post('/uploadImage'),
	delImage: requestFactory.post('/delImage'),

	addCategory: requestFactory.post('/addCategory'),
	//users auth
	renewToken: requestFactory.post('/renewToken'),
	updateUserToken: requestFactory.post('/users/requestToken'),

	prepDB: requestFactory.post('/db'),
	order: requestFactory.post('/order'),
	//not used yet
	adminGetAll: requestFactory.post('/adminGetAll'),
	userGetAll: requestFactory.post('/userGetAll'),
	getRawStats: requestFactory.post('/stats/getRawStats'),
	downloadStats: requestFactory.post('/stats/download'),

	// clearMachineLocation: requestFactory.patch('/machines/rmLocation'),

	// addMachineToLocation: requestFactory.patch('/locations/addMachine'),
	// addMachineToLocationForce: requestFactory.patch('/locations/addMachineForce'),
	// newLocation: requestFactory.post('/locations/newLocation'),
	// removeLocation: requestFactory.post('/locations/removeLocation'),
	// editLocation: requestFactory.post('/locations/editLocation'),
	// addLocationToUser: requestFactory.patch('/locations/addToUser'),
	// rmLocationFromUser: requestFactory.patch('/locations/rmFromUser'),

	startPasswordRestore: requestFactory.post('/users/startPasswordRestore'),
	setNewPassword: requestFactory.post('/users/setNewPassword'),
	changePassword: requestFactory.post('/users/resetPassword'),
	changeUserRole: requestFactory.post('/users/changeRole'),
	deleteUser: requestFactory.post('/users/deleteUser'),
}

export default DataService
