import React, {useRef, useState, useEffect} from 'react'
import './faq.css'
import {useWhyDidYouUpdate} from '../../utils/useWhyDidYouUpdate'
import faqsArray from './faqArray'
import {motion, AnimatePresence} from 'framer-motion'

// eslint-disable-next-line
import {isiOS} from '../../utils/browserCheck'
import {BlockScroll} from '../../utils/useLockBodyScroll'
import {FaRegTimesCircle, FaTimesCircle} from 'react-icons/fa'

const FAQSection = (props) => {
	const {id, forwardRef} = props
	useWhyDidYouUpdate('FAQ_section', props)

	return (
		<section
			// style={{ backgroundImage: `url(${TriangleBG})` }}
			// style={{
			// 	backgroundImage: `${isiOS ? '' : `url(${TriangleBG})`}`,
			// }}
			className='relative flex flex-col justify-center items-center bg-fixed bg-origin-border bg-main bg-left bg-cover bg-no-repeat pb-8'
			id='faq'
			data-section={id}
			ref={forwardRef}>
			{/* Frequently Asked Questions */}
			<h1 className='z-30 mt-8 relative w-full flex justify-center font-lucky text-5xl md:text-7xl tracking-wide md:tracking-wider leading-none'>FAQ</h1>
			<FaqList />
			{/* <DividerWave direction='up' /> */}
		</section>
	)
}

const FaqList = () => {
	const [faqs, setFaqs] = useState(faqsArray)
	const [showAllFaqs, setShowAllFaqs] = useState(false)

	const toggleFAQ = (id) => {
		setFaqs(
			// faqs.map((faq, i) => ({
			//     ...faq,
			//     open: i === id ? !faq.open : false,
			// }))
			faqs.map((faq, i) => {
				if (i === id) {
					return {...faq, open: !faq.open}
				}
				return faq
			})
		)
	}

	const handleShowAll = () => setShowAllFaqs(true)
	const handleCloseAll = () => {
		setShowAllFaqs(false)
		setFaqs(
			faqs.map((faq, i) => ({
				...faq,
				open: false,
			}))
		)
	}

	useEffect(() => {
		const modal = document.querySelector('.faq-modal')
		if (modal) {
			if (showAllFaqs) {
				setTimeout(() => modal.classList.add('show'), 10)
			} else {
				modal.classList.remove('show')
			}
		}
	}, [showAllFaqs])

	return (
		<>
			{showAllFaqs ? <BlockScroll /> : <></>}
			<div className='faqs max-w-3xl mx-auto w-full px-4'>
				{faqs
					.filter((faq) => !faq.hidden)
					.slice(0, 3)
					.map((faq, index) => (
						<FAQ faq={faq} index={index} key={index} toggleFAQ={toggleFAQ} />
					))}
				<div className='text-center' id='faqShowMore'>
					<button className='bg-transparent outline-none hover:underline' onClick={handleShowAll}>
						Show All
					</button>
				</div>
			</div>
			{showAllFaqs && (
				<div className='fixed inset-0 bg-white z-50 overflow-y-scroll faq-modal'>
					<BlockScroll />
					<div className='h-full max-w-3xl mx-auto px-4'>
						<div className='sticky top-0 z-10 py-6 bg-white bg-opacity-80 backdrop-blur-md flex justify-between items-center'>
							<h2 className='text-3xl font-bold'>Frequently Asked Questions</h2>
							<button className='text-2xl z-20 hover:text-gray-700 transition-colors duration-200' onClick={handleCloseAll}>
								<FaRegTimesCircle className='w-8 h-8' />
							</button>
						</div>
						{faqs
							.filter((faq) => !faq.hidden)
							.map((faq, index) => (
								<FAQ faq={faq} index={index} key={index} toggleFAQ={toggleFAQ} />
							))}
					</div>
				</div>
			)}
		</>
	)
}

const FAQ = ({faq, index, toggleFAQ}) => {
	return (
		<div className={`faq${faq.open ? ' open shadow-e_orange' : ''}`} onClick={() => toggleFAQ(index)}>
			<div className={`faq-question relative pr-5${faq.open ? ' font-semibold' : ''}`}>{faq.q}</div>
			<p className='faq-answer' dangerouslySetInnerHTML={{__html: faq.a}} />
		</div>
	)
}

export default FAQSection
