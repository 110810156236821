// Opera 8.0+
// eslint-disable-next-line no-undef
const isOpera = (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;

// Firefox 1.0+
const isFirefox = typeof InstallTrigger !== 'undefined';
if (isFirefox) console.log('using InstallTrigger to find FireFox browser. to remove sprites...')

// Safari 3.0+ "[object HTMLElementConstructor]" 
// eslint-disable-next-line no-undef
const isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification));

// Internet Explorer 6-11
const isIE = /*@cc_on!@*/false || !!document.documentMode;

// Edge 20+
const isEdge = !isIE && !!window.StyleMedia;

// Chrome 1 - 71
const isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

const isiOS = !window.MSStream && /iPad|iPhone|iPod/.test(navigator.userAgent); // fails on iPad iOS 13


// Blink engine detection
const isBlink = (isChrome || isOpera) && !!window.CSS;

export { isOpera, isFirefox, isSafari, isiOS, isIE, isEdge, isChrome, isBlink }