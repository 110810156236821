import React from 'react'

const CustomGlobalButton = ({
	text = 'Get mine now',
	className = '',
	gallery = false,
	galleryPremium = false,
	hero = false,
	contact = false,
	onClick = null,
	...props
}) => {
	return (
		<>
			<button
				onClick={onClick}
				{...props}
				className={`p-4 rounded-full font-lucky text-center 
					appearance-none select-none cursor-pointer 
					transition-all duration-200
					text-white
					outline-2 |outline
					|hover:outline |hover:outline-2
					|hover:outline-offset-4 |hover:bg-opacity-30
					|focus-visible:outline 
					|focus-visible:outline-2
		 			${
							gallery
								? 'outline-e_blue bg-e_blue hover:outline'
								: galleryPremium
								? 'outline-white bg-white bg-opacity-40'
								: hero
								? 'bg-e_red |outline-e_red |hover:outline-white'
								: contact
								? 'bg-black outline-black text-black hover:text-black hover:bg-white'
								: 'bg-purple-600 text-white'
						} 
					
					${className}
					`}>
				{text}
			</button>
		</>
	)
}

export default CustomGlobalButton
