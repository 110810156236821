import React from 'react'
import Border from '../components/Border'
import {marketingDoodle, meaningfulTouchDoodle, unforgettableGiftDoodle, updateSocialDoodle} from '../images'

import Pros_Marketing_PNG from '../images/pros/Pros_Marketing.png'
import Pros_Marketing_WEBP from '../images/pros/Pros_Marketing.webp'
import Meaningful_Touch_PNG from '../images/pros/Pros_PersonalTouch.png'
import Meaningful_Touch_WEBP from '../images/pros/Pros_PersonalTouch.webp'
import Unforgettable_Gift_PNG from '../images/pros/Pros_UnforgettableGift.png'
import Unforgettable_Gift_WEBP from '../images/pros/Pros_UnforgettableGift.webp'
import Update_Social_PNG from '../images/pros/Pros_SocialMedia.png'
import Update_Social_WEBP from '../images/pros/Pros_SocialMedia.webp'

const ProsPart = ({title, text, Doodle, artPNG, artWEBP}) => {
	return (
		<div className='grid grid-cols-1 md:grid-cols-2 gap-2 items-center justify-center max-w-screen-lg'>
			<div className='flex flex-row justify-center md:justify-end items-start'>
				<div className='w-24 h-auto relative mr-4'>
					<Doodle />
				</div>
				<div className='w-[15rem]'>
					<h1 className={` text-black font-lucky text-2xl`}>{title}</h1>
					<p>{text}</p>
				</div>
			</div>
			<div className='h-auto md:h-full relative mb-8 md:mb-0 '>
				<picture>
					<source type='image/webp' srcSet={artWEBP} />
					<source type='image/png' srcSet={artPNG} />
					<img src={artPNG} loading='lazy' alt={title} className='object-contain max-h-[50vh] h-fit w-full aspect-video' />
				</picture>
			</div>
		</div>
	)
}

const parts = [
	{
		title: 'UNFORGETTABLE GIFT',
		text: 'Why get the same old generic gift when you can get something creative, meaningful, unique and tailor-made.',
		Doodle: unforgettableGiftDoodle,
		artPNG: Unforgettable_Gift_PNG,
		artWEBP: Unforgettable_Gift_WEBP,
	},
	{
		title: 'MEANINGFUL AND PERSONAL TOUCH',
		text: 'Preserve that special moment or event in a personal timeless way that really touches the heart.',
		Doodle: meaningfulTouchDoodle,
		artPNG: Meaningful_Touch_PNG,
		artWEBP: Meaningful_Touch_WEBP,
	},
	{
		title: 'MARKETING',
		text:
			'Take advantage of the universal appeal of caricature and cartoon designs to give your brand presence a fun boost and distinct advantage above the competition.',
		Doodle: marketingDoodle,
		artPNG: Pros_Marketing_PNG,
		artWEBP: Pros_Marketing_WEBP,
	},
	{
		title: 'UPDATE SOCIAL',
		text: 'Update your social media avatars to a timeless and fun personal cartoon caricature that will stand out amongst the rest.',
		Doodle: updateSocialDoodle,
		artPNG: Update_Social_PNG,
		artWEBP: Update_Social_WEBP,
	},
]

const ProsSection = () => {
	return (
		<div className='relative flex flex-col justify-center content-center items-center mx-auto'>
			<div className='relative grid grid-cols-1 gap-8 auto-rows-fr pr-0 lg:pr-28 doodle'>
				{parts.map((part, index) => (
					<ProsPart key={index} title={part.title} text={part.text} Doodle={part.Doodle} artPNG={part.artPNG} artWEBP={part.artWEBP} />
				))}
			</div>
			<Border />
		</div>
	)
}

export default ProsSection
