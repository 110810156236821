import {useEffect, useRef} from 'react'
// https://usehooks.com/useWhyDidYouUpdate/

// Let's pretend this <Counter> component is expensive to re-render so ...
// ... we wrap with React.memo, but we're still seeing performance issues :/
// So we add useWhyDidYouUpdate and check our console to see what's going on.
// const Counter = React.memo((props) => {
//   useWhyDidYouUpdate("Counter", props);
//   return <div style={props.style}>{props.count}</div>;
// });

// Hook
export function useWhyDidYouUpdate(name, props) {
	// Get a mutable ref object where we can store props ...
	// ... for comparison next time this hook runs.
	const previousProps = useRef()

	useEffect(() => {
		if (previousProps.current) {
			// Get all keys from previous and current props
			const allKeys = Object.keys({...previousProps.current, ...props})
			// Use this object to keep track of changed props
			const changesObj = {}
			// Iterate through keys
			allKeys.forEach((key) => {
				// If previous is different from current
				if (previousProps.current[key] !== props[key]) {
					// Add to changesObj
					changesObj[key] = {
						from: previousProps.current[key],
						to: props[key],
					}
				}
			})

			// If changesObj not empty then output to console
			if (Object.keys(changesObj).length) {
				console.log('[why-did-you-update]', name, changesObj)
			}
		}

		// Finally update previousProps with current props for next hook call
		previousProps.current = props
	})
}
