const removeSprites = () => {
    const all_sprites = document.querySelectorAll('.sprite')
        all_sprites.forEach(sprite => {
            sprite.remove()
        })
}
const removeNoScript = ()=>{
    const all_noscript = document.querySelectorAll('noscript')
    all_noscript.forEach(noscript => {
        noscript.remove()
    });
}
export {removeSprites}
export {removeNoScript} 