import {debounce} from 'lodash'
import {useEffect, useState} from 'react'

export const useScreenDetector = () => {
	const [width, setWidth] = useState(window.innerWidth)

	const handleWindowSizeChange = () => {
		setWidth(window.innerWidth)
	}

	useEffect(() => {
		window.addEventListener('resize', debounce(handleWindowSizeChange, 250))

		return () => {
			window.removeEventListener('resize', handleWindowSizeChange)
		}
	}, [])

	const isMobile = width <= 734 // 768
	const isTablet = width > 734 && width <= 1040 //1024
	const isDesktop = width > 1040 //1024

	return {isMobile, isTablet, isDesktop}
}
