/* eslint-disable no-unused-vars */
import React, {useEffect, useState} from 'react'
import {useLockBodyScroll} from '../utils/useLockBodyScroll'

const LoadingPage = ({isLoading = true}) => {
	useLockBodyScroll()

	return (
		<div className='fixed top-0 left-0 flex items-center justify-center w-screen h-screen z-50 pointer-events-none'>
			{isLoading ? (
				<></>
			) : (
				<div className='flex items-center h-screen w-screen justify-center bg-slate-100 pointer-events-all'>
					<img src='/Loading.svg' alt='' className='flex items-center h-[80%] w-[60%] justify-center' />
				</div>
			)}
		</div>
	)
}

export default LoadingPage
