import axios from 'axios'
// import Config from '../Config'
// eslint-disable-next-line no-unused-vars
import {getToken, useLogout} from '../context/UserContext'
import {apiBaseUrl} from '../utils/config'

//
// TODO make this page a provider that provides quick access from everywhere and will let me catch errors easily
//
const createRequest = (method, path, errorMap) => {
	return (data) => {
		return axios({
			method: method,
			url: apiBaseUrl + path, //path should have a / before it coming into this function
			data: data,
			headers: {'x-access-token': getToken()},
		})
			.then((response) => response.data)
			.then(
				(response) => Promise.resolve(response),
				(error) => {
					if (!error.response) return Promise.reject('Network error')

					const serverErrorMessage = error.response.data?.error?.message
					if (serverErrorMessage && typeof serverErrorMessage === 'string') {
						return Promise.reject(serverErrorMessage)
					}

					const errorCode = error.response?.status
					if (errorCode === 418) {
						// const logout = useLogout()
						// logout()
						// console.log('tried to logout')
						return Promise.reject(error)
					}
					return Promise.reject((errorMap && errorMap[errorCode]) || `Unknown error ${errorCode}`)
				}
			)
	}
}

const requestFactory = {}
;['post', 'get'].forEach((method) => {
	requestFactory[method] = (path, errorMap) => createRequest(method, path, errorMap)
})

export default requestFactory
