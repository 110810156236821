import React from 'react'

import {isProduction} from '../utils/config'

//contact secretary
import {ReactComponent as SecretaryStandbyDarkDress} from './contact/secretary/Standby_DarkDress.svg'
import {ReactComponent as SecretaryStandbyLineDress} from './contact/secretary/Standby_LineDress.svg'
import {ReactComponent as SendNoteDarkDress} from './contact/secretary/SendNote_DarkDress.svg'
import {ReactComponent as SendNoteLineDress} from './contact/secretary/SendNote_LineDress.svg'
import {ReactComponent as TakingNotesDarkDress} from './contact/secretary/TakingNotes_DarkDress.svg'
import {ReactComponent as TakingNotesLineDress} from './contact/secretary/TakingNotes_LineDress.svg'
// const requireContext = require('require-context')
// eslint-disable-next-line no-unused-vars
const baseUrl = process.env.ASSETS_BASE_URL | 'https://assets.adelezstudio.com'
// eslint-disable-next-line no-unused-vars
const serveFromLocal = isProduction ? false : true

// eslint-disable-next-line
// function importAll(r, asArray = true) {
// 	let imagesArray = []
// 	r.keys().forEach((item) => {
// 		imagesArray.push(r(item))
// 	})
// 	return imagesArray
// }

// eslint-disable-next-line
function importAll(r, asArray = true) {
	const images = {}
	const sizes = ['sm', 'md', 'lg', 'thumb', 'full']
	const fileTypes = ['webp', 'png', 'jpeg', 'jpg']
	let imagesArray = []

	r.keys().forEach((key) => {
		const parts = key.split('/')
		const fileName = parts[1]
		const size = sizes.find((s) => sizes.includes(parts[2]))
		const fileType = fileTypes.find((type) => key.endsWith(type))

		if (!images[fileName]) {
			images[fileName] = {name: fileName}
		}
		if (!images[fileName][size ? size : 'full']) {
			images[fileName][size ? size : 'full'] = []
		}
		images[fileName][size ? size : 'full'].push({type: fileType, path: r(key)}) // Assign the image to the corresponding size and type
	})

	// Ensure all sizes are populated for all images
	Object.keys(images).forEach((fileName) => {
		sizes.forEach((s) => {
			if (s !== 'thumb' && s !== 'name') {
				// Only assign if the key is null
				if (images[fileName][s] === undefined) {
					// Find the first non-null size value
					const nonNullValue = sizes.filter((size) => size !== 'thumb' && size !== 'name').find((size) => images[fileName][size] !== undefined)
					if (nonNullValue) {
						images[fileName][s] = images[fileName][nonNullValue]
					}
				}
			}
		})
	})

	if (asArray) {
		imagesArray = Object.values(images).map((image) => image)
		console.log('importAll imagesArray', imagesArray)
		return imagesArray
	} else {
		console.log('importAll images', images)
		return images
	}
}

const basicGalleryImages = importAll(require.context('./gallery/basic', true, /\.(png|jpe?g|svg|png)$/))
const standardGalleryImages = importAll(require.context('./gallery/standard', true, /\.(png|jpe?g|svg|png)$/))
const premiumGalleryImages = importAll(require.context('./gallery/premium', true, /\.(png|jpe?g|svg|png)$/))
// const customGalleryImages = importAll(require.context('./caricatures/custom', false, /\.(png|jpe?g|svg)$/))

const caricatures = [...basicGalleryImages, ...standardGalleryImages, ...premiumGalleryImages] // ...customGalleryImages
export {caricatures, basicGalleryImages, premiumGalleryImages, standardGalleryImages} //customGalleryImages

export const getRandomCaricature = (amount = 1) => {
	const savedCaris = []
	const data = []
	const maxTry = caricatures.length
	for (let i = 1, m = 1; i <= amount && m <= maxTry; i++, m++) {
		const randomNumber = Math.floor(Math.random() * (maxTry - 1) + 1)

		if (m === maxTry) console.log('m = maxTry reached in getRandomCaricature')
		if (savedCaris.includes(randomNumber)) i--
		else {
			data.push(caricatures[randomNumber])
			savedCaris.push(randomNumber)
		}
	}

	return data
}

//hero

export {ReactComponent as ForGallery} from './gallery/ForGallery.svg'

//how it works
export {default as howStep1} from './howitworks/HowItWorks_mobile1.png'
export {default as howStep2} from './howitworks/HowItWorks_mobile2.png'
export {default as howStep3} from './howitworks/HowItWorks_mobile3.png'
export {default as howDesktop} from './howitworks/HowItWorks_desktop.png'
export {ReactComponent as GreenArrowDown} from './howitworks/GreenArrowDown.svg'

//other
export {ReactComponent as Logo} from './other/logo.svg'
export {ReactComponent as LogoTxt} from './other/logo_txt.svg'

//pros section
export {default as marketingArt} from './pros/Pros_Marketing.png'
export {default as meaningfulTouchArt} from './pros/Pros_PersonalTouch.png'
export {default as updateSocialArt} from './pros/Pros_SocialMedia.png'
export {default as unforgettableGiftArt} from './pros/Pros_UnforgettableGift.png'
export {ReactComponent as marketingDoodle} from './pros/pros_icon-cash.svg'
export {ReactComponent as meaningfulTouchDoodle} from './pros/pros_icon-pizza.svg'
export {ReactComponent as unforgettableGiftDoodle} from './pros/pros_icon-present.svg'
export {ReactComponent as updateSocialDoodle} from './pros/pros_icon-profile.svg'

//gallery
export {default as Gallery_ShowBoy_sm} from './gallery/Gallery_ShowBoy_sm.webp'
export {default as Gallery_ShowBoy_lg} from './gallery/Gallery_ShowBoy_lg.webp'

export {default as GalleryBlackWhiteLeft} from './gallery/GalleryBlackWhiteLeft.webp'
export {default as GalleryBlackWhiteRight} from './gallery/GalleryBlackWhiteRight.webp'

export {default as GalleryColorLeft} from './gallery/GalleryColorLeft.webp'
export {default as GalleryColorRight} from './gallery/GalleryColorRight.webp'

export {default as GalleryPainterlyLeft} from './gallery/GalleryPainterlyLeft.webp'
export {default as GalleryPainterlyRight} from './gallery/GalleryPainterlyRight.webp'

//enough talk
// export {default as EnoughTalk_BottomDoodle_D} from './enoughtalk/EnoughTalk_BottomDoodle_D.png'
// export {default as EnoughTalk_BottomDoodle_M} from './enoughtalk/EnoughTalk_BottomDoodle_M.png'
// export {default as EnoughTalk_ExcitedWomen_D} from './enoughtalk/EnoughTalk_ExcitedWomen_D.png'
// export {default as EnoughTalk_ExcitedWomen_M} from './enoughtalk/EnoughTalk_ExcitedWomen_M.png'

// export {ReactComponent as AKCariSitting} from './about/AKCariSitting.svg'
// export {ReactComponent as DoodleOnText} from './about/DoodleOnText.svg'

// footer
export {ReactComponent as CopyCop} from './footer/CopyCop.svg'
export {ReactComponent as CopyCopMin} from './footer/CopyCop.min.svg'

// contact
export {default as ContactBG} from './contact/ContactBG.png'

const lineOrDark = Math.random() * 2 > 1
console.log('using random secretary dress style true=lines, false=dark : ', lineOrDark)

export const SendNote = () => (lineOrDark ? <SendNoteLineDress /> : <SendNoteDarkDress />)
export const SecretaryStandby = () => (lineOrDark ? <SecretaryStandbyLineDress /> : <SecretaryStandbyDarkDress />)
export const TakingNotes = () => (lineOrDark ? <TakingNotesLineDress /> : <TakingNotesDarkDress />)
// eslint-disable-next-line
export default {SendNote, SecretaryStandby, TakingNotes}
