import {createContext, useContext, useEffect, useState} from 'react'
// import {createContext, useContext, useEffect, useState} from 'react'
import UserService from '../services/UserService'
import jwt from 'jwt-decode'
import DataService from '../services/DataService'
import {useNotification} from './NotificationContext'

const LOCAL_STORAGE_USER_KEY = 'user'

const UserRole = UserService.roles

const NullUser = {
	details: {},
	token: '',
	role: UserRole.guest,
}

const UserStateContext = createContext()
const UserDispatchContext = createContext()
const UserLoginStateContext = createContext()

function tokenToUser(token) {
	const data = jwt(token)
	return {
		details: {
			id: data.id,
			name: data.name,
			email: data.email,
		},
		token: token,
		role: data.role,
		ttl: data.exp * 1000,
	}
}

const UserProvider = ({children}) => {
	const loadedUser = JSON.parse(localStorage.getItem(LOCAL_STORAGE_USER_KEY)) || NullUser

	const {setToast} = useNotification()

	const [user, dispatch] = useState(loadedUser)
	// this code renewe the use account token so user doesnt get logged out,
	// but it doesnt work if user was away for too long,
	// need to find the right balance between time to live and time to end
	const autoDispatch = (user = null) => {
		if (user) {
			localStorage.setItem(LOCAL_STORAGE_USER_KEY, JSON.stringify(user))
			dispatch(user)
			setToast('Welcome back ' + user.details.email, 'update')
		} else {
			localStorage.removeItem(LOCAL_STORAGE_USER_KEY)
			dispatch(NullUser)
		}
	}
	useEffect(() => {
		if (user.role === UserRole.guest) {
			return
		}

		const timeAheadMs = 5 * 60 * 1000
		const now = Date.now()
		const ttl = Math.max(user.ttl - timeAheadMs, now)
		const timeout = setTimeout(async () => {
			console.error('need to implement code to renew token')
			try {
				setToast('trying to update token', 'info')
				const response = await DataService.updateUserToken({oldToken: user.token})
				const decodedUser = tokenToUser(response.token)
				if (!response.token) setToast('failed to update token, please log out and back in', 'error')

				autoDispatch(decodedUser)
			} catch (e) {
				setToast('trying to update token catch e', 'error')
				console.log(e)
				autoDispatch()
			}
		}, Math.max(ttl - now - timeAheadMs, 0))

		return () => {
			clearTimeout(timeout)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user])

	return (
		<UserStateContext.Provider value={user}>
			<UserDispatchContext.Provider value={dispatch}>
				<UserLoginStateContext.Provider value={user.role !== UserRole.guest}>{children}</UserLoginStateContext.Provider>
			</UserDispatchContext.Provider>
		</UserStateContext.Provider>
	)
}

const useUser = () => {
	const context = useContext(UserStateContext)
	if (context === undefined) {
		throw new Error('useUserState must be used within a UserProvider')
	}

	return context
}

const useUserDispatch = () => {
	const context = useContext(UserDispatchContext)
	if (context === undefined) {
		throw new Error('useUserDispatch must be used within a UserProvider')
	}

	return context
}

const useLogin = () => {
	const dispatch = useUserDispatch()
	const {setToast} = useNotification()

	// console.log('useLogin', dispatch)

	return (data) => {
		// console.log('useLogin', data)
		const user = tokenToUser(data)
		// console.log('useLogin user', user)
		localStorage.setItem(LOCAL_STORAGE_USER_KEY, JSON.stringify(user))
		dispatch(user)
		setToast('Logged in')
	}
}

const useLogout = () => {
	const dispatch = useUserDispatch()
	const {setToast} = useNotification()
	return () => {
		localStorage.removeItem(LOCAL_STORAGE_USER_KEY)
		dispatch(NullUser)
		setToast('Logged out')
	}
}

const useUserLoggedIn = () => {
	const context = useContext(UserLoginStateContext)
	if (context === undefined) {
		throw new Error('useUserLoggedIn must be used within a UserProvider')
	}

	return context
}

const getToken = () => {
	const user = JSON.parse(localStorage.getItem(LOCAL_STORAGE_USER_KEY))
	return user?.token || ''
}

const useIsAdmin = () => {
	return useUser().role === UserRole.admin
}

export {UserProvider, UserRole, useUser, useUserLoggedIn, useLogin, useLogout, getToken, useIsAdmin}
