export const scrollIntoView = (event, hashTag) => {
	if (hashTag) {
		if (hashTag[0] === '#') hashTag = hashTag.slice(1)
		const el = document.getElementById(hashTag)
		if (!el) return
		if (!isElementInViewport(el)) {
			el.scrollIntoView({behavior: 'smooth'})
			window.history.pushState({}, null, '#' + hashTag)
		}
		return
	}
	const target = event.target
	if (
		// target.tagName === 'A' &&
		target.origin === window.location.origin &&
		target.hash
	) {
		event.preventDefault()
		const el = document.getElementById(target.hash)
		el.scrollIntoView({behavior: 'smooth'})
		window.history.pushState({}, null, target.href)
		// if (target.pathname !== prevPathname) {
		// await setVisiblePage(target)
		// }
	}
}
export const scrollToBottom = (el) => {
	console.log('scrollToBottom to ' + el.toString())
	// if (el[0] !== '#') {
	const elem = document.getElementById('#' + el)
	// }

	elem.scrollIntoView({behavior: 'smooth', block: 'end', inline: 'nearest'})
}
function isElementInViewport(el) {
	try {
		const rect = el.getBoundingClientRect()
		return (
			rect.top >= 0 &&
			rect.left >= 0 &&
			rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
			rect.right <= (window.innerWidth || document.documentElement.clientWidth)
		)
	} catch (error) {
		console.log(error)
		return true
	}
}
