import {createContext, useContext} from 'react'

import {ToastContainer, toast} from 'react-toastify'
// Define context
const NotificationContext = createContext({
	setToast: (msg, props) => toast(msg, {...props}),
})
const NotificationProvider = ({children}) => {
	const success = (msg, props) => toast.success(msg, {...props})
	const error = (msg, props) => toast.error(msg, {...props})
	const warning = (msg, props) => toast.warning(msg, {...props})
	const info = (msg, props) => toast.info(msg, {...props})

	const setToast = (msg, type = 'default', props = {closeOnClick: false, autoClose: 5000}) => {
		switch (type) {
			case 'success':
				success(msg, props)
				break
			case 'error':
				error(msg, {autoClose: 20000, ...props})
				break
			case 'warning':
				warning(msg, {autoClose: 10000, ...props})
				break
			case 'info':
				info(msg, props)
				break
			default:
				toast(msg, {...props})
		}
	}

	return (
		<NotificationContext.Provider value={{setToast}}>
			{children}
			<ToastContainer
				position='bottom-right'
				// autoClose={4000}
				hideProgressBar={false}
				newestOnTop={false}
				// closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
				theme='light'
			/>
		</NotificationContext.Provider>
	)
}

const useNotification = () => useContext(NotificationContext)

export {NotificationProvider, useNotification}
